.maincontainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  background-color: #0e0e0e;
  color: #FFFFFF;
}

.spacer-large {
  height: 10vh;
}

p {
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
}

h1 {
  font-size: xxx-large;
}